define('emberx-slider/components/x-slider', ['exports', 'ember', 'ember-binding-macros/mixins/property-bindings'], function (exports, Ember, PropertyBindings) {

  'use strict';

  exports['default'] = Ember['default'].Component.extend(PropertyBindings['default'], {
    type: "range",
    tagName: ['input'],
    classNames: ['x-slider'],
    propertyBindings: ['value > element.value'],
    attributeBindings: ['min', 'max', 'step', 'type', 'name'],

    /**
     * The minimum value that this component's `value` property may
     * have.
     *
     * @property min
     * @type {Number}
     * @default 0
     */
    min: 0,

    /**
     * The maximum value (inclusive) that this component's `value` may
     * have.
     *
     * @property max
     * @type {Number}
     * @default 100
     */
    max: 100,

    /**
     * The "granularity". The value of the input will be a multiple of
     * this number.
     *
     * @property step
     * @type {Number}
     * @default 0
     */
    step: 1,

    /**
     * The current value of the input. It will lie on or between `min`
     * and `max` and will be a multiple of `step`.
     *
     * @property value
     * @type {Number}
     * @default 0
     */
    value: 0,

    /**
     * On any `input` event, copy the numeric value of the DOM element
     * onto the `value` property of the component so that it can be
     * bound to and from.
     *
     * @private
     */
    input: function() {
      this.set('value', Number(this.get('element.value')).valueOf());
    },

    /**
     * Make sure that the element.value is set as soon as the element
     * becomes available.
     *
     * @override
     */
    didInsertElement: function() {
      this.set('element.value', this.get('value'));
    }
  });

});